<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款人">
          <el-input v-model="dataForm.payeeUser" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款人号码">
          <el-input v-model="dataForm.payeePhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button size="small" @click="getDataList()" type="primary">查询</el-button>
        <el-button size="small" @click="downLoad()" type="success">导出</el-button>
        <el-form-item style="text-align: right;float: right;">
          <el-button size="small" @click="operatopnList('pass','','',2)"
                     type="primary"
                     v-if="newAuth('supplier:fee:auditStatus1')"
                     :disabled="selectFeeData.length ? false : true">费用审核通过</el-button>
          <el-button size="small" @click="operatopnList('pass','','',1)"
                     type="primary"
                     v-if="newAuth('supplier:fee:auditStatus2')"
                     :disabled="selectFeeData.length ? false : true">反审核</el-button>
          <el-button size="small" type="success" @click="batchWrite(1)" :disabled="!selectFeeData.length">已付款核销</el-button>
          <el-button size="small" type="success" @click="batchWrite(2)" :disabled="!selectFeeData.length">反核销</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small"
                :height="tableHeight"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading"
                style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime }}
          </template>
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true"
                         fixed="left">
        </el-table-column>
        <el-table-column label="司机车牌" prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true"
                         fixed="left">
        </el-table-column>
        <el-table-column label="实际车型" :formatter="dictConvert" prop="orderInfo.vehicleType" width="80"
                         :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="供应商" prop="vehiclePlan.motorcadeName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="运费" prop="supplierCost.freightFee" :formatter="getNumValue" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="其他费用" prop="supplierCost.otherFee" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="备注" prop="supplierCost.remark" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="费用小计" prop="payableAmounts" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmounts" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmounts" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收款人" prop="supplierCost.payeeUser" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收款人电话" prop="supplierCost.payeePhone" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车次号" prop="vehiclePlan.planNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="应付车型" prop="vehiclePlan.vehicleTypeName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款状态" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.supplierCost.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.supplierCost.writeStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" fixed="right" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.supplierCost.status == 1" size="small" type="danger">未审核</el-tag>
            <el-tag v-if="scope.row.supplierCost.status == 2" size="small" type="success">已审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button type="text" size="small"
                         @click="operatopnList('cost', 'view', scope.row)">详情
              </el-button>
              <!-- 未付款的情况下可以修改数据 -->
              <el-button
                         type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
  </div>
</template>

<script>
import costModification from './detail/order-supplier-opup'
import writeOffPopup from './detail/write-off-popup'

export default {
  data () {
    return {
      dataForm: {
        takeGoodsTime: null,
        vehiclePlate: null,
        driverName: null,
        customerName: null,
        payeeUser: null,
        payeePhone: null,
        writeStatus: null
      },
      tableHeight: '65vh',
      costType: 2,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      payCostData: {
        ids: []
      },
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      genBillVisible: false,
      writeOffVisible: false,
      requestVisible: false,
      selectFeeData: []
    }
  },
  components: {
    costModification,
    writeOffPopup
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    batchWrite (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.supplierCost.writeStatus !== writeStatus) {
          ids.push(item.supplierCost.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/supplierCost/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    operatopnList (type, todo, row, status) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.supplierCost.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.supplierCost.id)
          })
        }
        this.$confirm(status === 2 ? '正在执行【费用审核通过】操作，是否继续?' : '正在执行【反审核】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/supplierCost/batchAudit'),
            method: 'post',
            data: {
              ids: ids,
              status: status
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => {})
      } else if (type === 'cost') {
        this.$refs.cost.init(type, todo, row)
      }
    },
    /**
     *自定义合计
     */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          if (column.property && (column.property.indexOf('supplierCost.freightFee') > -1)) {
            return this.getValue(item.supplierCost.freightFee === undefined ? 0 : item.supplierCost.freightFee)
          }
          // 维保金额
          if (column.property && (column.property.indexOf('supplierCost.otherFee') > -1)) {
            return this.getValue(item.supplierCost.otherFee === undefined ? 0 : item.supplierCost.otherFee)
          }
          if (column.property && (column.property.indexOf('payableAmounts') > -1)) {
            return this.getValue(item[column.property] === undefined ? 0 : item[column.property])
          }
          if (column.property && (column.property.indexOf('paidAmounts') > -1)) {
            return this.getValue(item[column.property] === undefined ? 0 : item[column.property])
          }
          if (column.property && (column.property.indexOf('notPayableAmounts') > -1)) {
            return this.getValue(item[column.property] === undefined ? 0 : item[column.property])
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (row) {
      for (let c in row) {
        row[c] = this.getValue(row[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', row.supplierCost.freightFee, row.supplierCost.otherFee, '',
        row.payableAmounts, row.paidAmounts, row.notPayableAmounts, '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/export/supplierCostExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'supplierCost.payeeUser': this.dataForm.payeeUser,
          'supplierCost.payeePhone': this.dataForm.payeePhone,
          'SupplierCost.writeStatus': this.dataForm.writeStatus
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '供应商费用池.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.payCostData = {
        ids: []
      }
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/supplierCost/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'customerName': this.dataForm.customerName,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'SupplierCost.payeeUser': this.dataForm.payeeUser,
          'SupplierCost.payeePhone': this.dataForm.payeePhone,
          'SupplierCost.writeStatus': this.dataForm.writeStatus
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          if (data.addressTotal != null || data.addressTotal !== undefined) {
            this.getAllSummaries(data.addressTotal || {})
          }
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
        this.tableHeight = '67vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-supplierFee {
}
</style>
